import { Component, OnInit } from "@angular/core";
import { SettingService } from "src/app/_services/setting.service";
@Component({
  selector: "app-card-profile",
  templateUrl: "./card-profile.component.html",
  styleUrls: ["./card-profile.component.scss"]
})
export class CardProfileComponent implements OnInit {
  user: any;
  errorMessage: any;
  constructor(private settingService: SettingService) {}

  ngOnInit(): void {
    this.getsetting()
  }
  async getsetting() {
    await this.settingService.getDashboard().subscribe({
      next: data => {
        this.user = data;
        // console.log(this.user.membreFamille);
      },
      error: err => {
        this.errorMessage = err.error.message;
      }}
    );
  }

}
