import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BonsService } from 'src/app/_services/bons.service';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/_services/auth.service';

interface FilePreview {
  name: string;
  type: string;
  size: number;
  previewUrl: string;
}
@Component({
  selector: 'app-ordonnances',
  templateUrl: './ordonnances.component.html',
  styleUrls: ['./ordonnances.component.css']
})
export class OrdonnancesComponent {
  overlay = false;
  errorMessage :any;
  data: any ;
  file:any;
  tel: string = ""
  public files: FilePreview[] = [];

  public isDragging = false;

  constructor (private bonsService : BonsService, private router : Router, private authService : AuthService){

  }
  onFileSelected(files: FileList) {
    this.files = []
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      this.file = file;
      this.previewFile(file);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = false;
    this.files = []
    const files = event.dataTransfer.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      this.file = file;
      this.previewFile(file);
    }
  }

  previewFile(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const filePreview: FilePreview = {
        name: file.name,
        type: file.type,
        size: file.size,
        previewUrl: reader.result as string
      };
      this.files.push(filePreview);
    };
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
  }

  onUpload() {
    if(this.authService.getEligible() === "ST00002")
    {

      if (this.file && this.tel != "") {
        const formData: FormData = new FormData();
       
        formData.append('ordonnance', this.file, this.file.name);
        formData.append('telephone', this.tel)
        this.bonsService.rembordonn(formData).subscribe(response=> {
            Swal.fire('MSAE', 'Votre demande de remboursement d\'ordonnance a été prise en compte. Une date de rendez-vous vous sera communiquée trés prochainement.', 'success');
            
            this.router.navigateByUrl('/admin/dashboard').then(() => { // Redirige vers la page d'accueil après la connexion
            });
        }, error => {
            Swal.fire('MSAE', 'Une erreur s\'est produite lors de l\'envoi de votre demande. Veuillez réessayer ultérieurement.', 'error');
        }
        )
        
      }
      else{
          Swal.fire('MSAE', 'L\'ordonnance est obligatoire', 'warning');
      }
    }
    else{ 
      Swal.fire('Eligibilité', "Vous n'êtes pas éligible",'warning');
    }
  }
}
