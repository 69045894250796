import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'src/app/_services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/_services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  passwordData = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };
 

changePassword: FormGroup

  constructor(private http: HttpClient, private authService: AuthService, private formbuilder: FormBuilder, private router : Router) {}
  ngOnInit(): void {
    this.changePassword = this.formbuilder.group({
      currentPassword: [null, [Validators.required]],
      newPassword: [null, [Validators.required]],
      confirmPassword: [null, [Validators.required]]

    })
  }

  onSubmit() {

    const valueForm = this.changePassword.value
    if( this.authService.getActualPassword() === valueForm.currentPassword) {
      if ((
        valueForm.currentPassword  === valueForm.newPassword
      )) {
        Swal.fire ("Mot de Passe","l'Ancien Mot de Passe Correspond au nouveau mot  de passe",'warning')
      }
      else if(
        valueForm.confirmPassword != valueForm.newPassword
      )
      {
        Swal.fire ("Mot de passe ","Les Mots de Passe ne sont pas Conformes",'warning')
  
      }
      else {
        this.authService.changePassword(valueForm.currentPassword,valueForm.newPassword).subscribe((value) => {
          Swal.fire ("Mot de passe","Votre mot de passe a été changez avec succés !",'success')
          this.router.navigate(['admin', 'dashboard'])
        } )
      }
    }
    else {
      Swal.fire('Mot de Passe', 'votre mot de passe est incorrect', 'error');
    }
  }
  
}
