import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';

import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-leaflet',
  templateUrl: './leaflet.component.html',
  styleUrls: ['./leaflet.component.scss']
})
export class LeafletComponent  implements OnInit {
  private map: L.Map;
      ngOnInit(): void {
        this.map = L.map('map', {
          zoomControl: false // hide the default zoom control
        }).setView([14.7167, -17.4677], 14.);
      
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: 'Map data &copy; OpenStreetMap contributors'
        }).addTo(this.map);
      
        // Set the bounds of the map to cover Senegal
        const southWest = L.latLng(12.3071, -17.5350);
        const northEast = L.latLng(16.6915, -11.3553);
        const bounds = L.latLngBounds(southWest, northEast);
        this.map.fitBounds(bounds);
      
        // Set the maximum bounds of the map to prevent the user from scrolling outside Senegal
        const maxBounds = bounds;
        this.map.setMaxBounds(maxBounds);
      
        // Add a custom zoom control to the map
        L.control.zoom({
          position: 'topright'
        }).addTo(this.map);
        const locations = [
          { name: 'Company A', lat: 51.5, lng: -0.1 },
          { name: 'Company B', lat: 51.51, lng: -0.12 },
          { name: 'Company C', lat: 51.49, lng: -0.08 }
        ];

        const companyIcon = L.icon({
          iconUrl: 'assets/company-icon.png',
          iconSize: [32, 32], // size of the icon
          iconAnchor: [16, 32], // point of the icon which will correspond to marker's location
          popupAnchor: [0, -32] // point from which the popup should open relative to the iconAnchor
        });
        
        // Use the custom icon for each marker
        locations.forEach(location => {
          const marker = L.marker([location.lat, location.lng], { icon: companyIcon }).addTo(this.map);
          marker.bindPopup(`<b>${location.name}</b>`).openPopup();
        });
    
    
        
      }
  
}
