import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-card-settings",
  templateUrl: "./card-settings.component.html",
  styleUrls: ["./card-settings.component.scss"]
})
export class CardSettingsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
