import { Component, OnInit } from "@angular/core";
import { SettingService } from "src/app/_services/setting.service";
import { AuthGuard } from 'src/app/_guards/authguard.guard';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls:["./admin.component.scss"]
})
export class AdminComponent implements OnInit {
  user: any;
  errorMessage: any;
  auth: any;
  router: any;
  constructor(private settingService: SettingService,private authService: AuthService) {}

  ngOnInit(): void {
    // this.getsetting()
  }
  // async getsetting() {
  //   await this.settingService.getDashboard().subscribe({
  //     next: data => {
  //       this.user = data;
  //       // console.log(this.user.membreFamille);
  //     },
  //     error: err => {
  //       this.errorMessage = err.error.message;
  //     }}
  //   );
  // }

  
  logout() {
    this.authService.logout();
    this.auth.setConnection();
    this.router.navigateByUrl('/login')
  }

}
