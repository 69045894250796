import { Component } from '@angular/core';
import { SpinnerserviceService } from 'src/app/_services/spinnerservice.service';

@Component({
  selector: 'app-spinnersdash',
  templateUrl: './spinnersdash.component.html',
  styleUrls: ['./spinnersdash.component.css']
})
export class SpinnersdashComponent {
  isLoading$ = this.spinnerService.loading$;
  constructor(public spinnerService: SpinnerserviceService) {}
}
