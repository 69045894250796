import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  connected = false;

  constructor(private router: Router, private authService: AuthService) {}

  getConnected() {
    return this.connected;
  }

  setConnection() {
    this.connected = !this.connected;
  }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean  {
    
      if (this.connected == true) {
        return true;
      }
      else {
        this.router.navigate(['/login']);
        return false;
      }
  
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean  {
    return this.canActivate(childRoute,state);
  }


}