import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private baseUrl = environment.baseurl+'authentification/v1/login';
  private changePasswordUrl = environment.baseurl+'/api/v1/changePwd'
  private token: string;
  private eligible: string;
  private actualPassword: string;
 

  constructor(private http: HttpClient, private router: Router,) {}

  login(data: any): Observable<any> {

    const httpHeader: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    return this.http.post(this.baseUrl, data, httpOptions);
    
  }

  logout(): void {
    this.token = null;
    localStorage.removeItem('token');
    this.router.navigateByUrl('/').then(() => { // Redirige vers la page d'accueil après la connexion
      window.location.reload(); // Force la réinitialisation de l'état de la navigation
    });
  }

  isLoggedIn(): boolean {
    this.token = this.loadToken()
    return !!this.token
  }

  setToken(token: string): void {
    this.token = token;
    localStorage.setItem('token', token);
  }

  loadToken(): string {
    const token = localStorage.getItem('token');
    if (token) {
      return this.token = token;
    }
  }

  getEligible(): string{
    return this.eligible;
  }

  setEligible(eligible: any) {
    this.eligible = eligible;
  }


  getActualPassword(): string {
    return this.actualPassword
  }

  setPassword(password: string) {
    this.actualPassword = password
  }
//}
  //ajout du 12/08/2024
// Méthode pour changer le mot de passe
  changePassword(currentPassword: string, newPassword: string): Observable<any> {

    const data={
      password : currentPassword,
      newPassword : newPassword
    }
    return this.http.post(this.changePasswordUrl ,data);
  }
  contact(data: any): Observable<any> {
    return this.http.post("https://portail.msae.sn:8081/api/sendmail/test", data);

  }

}
