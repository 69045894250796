import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BonsService } from 'src/app/_services/bons.service';
import { PartenaireService } from 'src/app/_services/partenaire.service';
import Swal from 'sweetalert2';
interface FilePreview {
  name: string;
  type: string;
  size: number;
  previewUrl: string;
}
@Component({
  selector: 'app-bons',
  templateUrl: './bons.component.html',
  styleUrls: ['./bons.component.scss']
})
export class BonsComponent {
  overlay = false;
  errorMessage :any;
  data: any ;
  file:any;
  pharmacies: any;
  public files: FilePreview[] = [];
  bonForm = new FormGroup({
      prescripteur: new FormControl(''),
      ordonnance: new FormControl(''),
      pharmacies: new FormControl('')
  });
  public isDragging = false;
  data1:any= [
    {
        label: 'Bakel',
        options: [
            { value: 'BKYG', label: 'PHARMACIE YAGUINE' },
            { value: 'BKD', label: 'Pharmacie DIAWARA', disabled: true },
            { value: 'BKG', label: 'Pharmacie du GOY'},
            { value: 'BKB', label: 'Pharmacie BOUNDOU'},
        ],
    },
    {
        label: 'Pacific Time Zone',
        options: [
            { value: 'CA', label: 'California' },
            { value: 'NV', label: 'Nevada' },
            { value: 'OR', label: 'Oregon' },
            { value: 'WA', label: 'Washington' },
        ],
    },
    {
        label: 'Mountain Time Zone',
        options: [
            { value: 'AZ', label: 'Arizona' },
            { value: 'CO', label: 'Colorado' },
            { value: 'ID', label: 'Idaho' },
            { value: 'MT', label: 'Montana' },
            { value: 'NE', label: 'Nebraska' },
            { value: 'NM', label: 'New Mexico' },
            { value: 'ND', label: 'North Dakota' },
            { value: 'UT', label: 'Utah' },
            { value: 'WY', label: 'Wyoming' },
        ],
    },
    {
        label: 'Central Time Zone',
        options: [
            { value: 'AL', label: 'Alabama' },
            { value: 'AR', label: 'Arkansas' },
            { value: 'IL', label: 'Illinois' },
            { value: 'IA', label: 'Iowa' },
            { value: 'KS', label: 'Kansas' },
            { value: 'KY', label: 'Kentucky' },
            { value: 'LA', label: 'Louisiana' },
            { value: 'MN', label: 'Minnesota' },
            { value: 'MS', label: 'Mississippi' },
            { value: 'MO', label: 'Missouri' },
            { value: 'OK', label: 'Oklahoma' },
            { value: 'SD', label: 'South Dakota' },
            { value: 'TX', label: 'Texas' },
            { value: 'TN', label: 'Tennessee' },
            { value: 'WI', label: 'Wisconsin' },
        ],
    },
    {
        label: 'Eastern Time Zone',
        options: [
            { value: 'CT', label: 'Connecticut' },
            { value: 'DE', label: 'Delaware' },
            { value: 'FL', label: 'Florida' },
            { value: 'GA', label: 'Georgia' },
            { value: 'IN', label: 'Indiana' },
            { value: 'ME', label: 'Maine' },
            { value: 'MD', label: 'Maryland' },
            { value: 'MA', label: 'Massachusetts' },
            { value: 'MI', label: 'Michigan' },
            { value: 'NH', label: 'New Hampshire' },
            { value: 'NJ', label: 'New Jersey' },
            { value: 'NY', label: 'New York' },
            { value: 'NC', label: 'North Carolina' },
            { value: 'OH', label: 'Ohio' },
            { value: 'PA', label: 'Pennsylvania' },
            { value: 'RI', label: 'Rhode Island' },
            { value: 'SC', label: 'South Carolina' },
            { value: 'VT', label: 'Vermont' },
            { value: 'VA', label: 'Virginia' },
            { value: 'WV', label: 'West Virginia' },
        ],
    },
];
constructor (private bonsService : BonsService, private router : Router){

}
/*
change(key: string, event: Event) {
  console.log(key, event);
}
search(text: string) {
  this.data1 = text
      ? (JSON.parse(JSON.stringify(this.data1)) as Select2Option[]).filter(
            option => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1,
        )
      : JSON.parse(JSON.stringify(this.data1));
}
update(key: string, event: Select2UpdateEvent<any>) {
  // console.log(event.value);
  this.bonForm.value.pharmacies = event.value;
  this.pharmacies = event.value;
  console.log(this.bonForm.value.pharmacies);
}
value1 = 'CA';

  ngOnInit() {
    
  }
  
  onFileSelected(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      this.file = file;
      this.previewFile(file);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = false;
    const files = event.dataTransfer.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      this.previewFile(file);
    }
  }

  previewFile(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const filePreview: FilePreview = {
        name: file.name,
        type: file.type,
        size: file.size,
        previewUrl: reader.result as string
      };
      this.files.push(filePreview);
    };
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
  }

  onUpload() {
    console.log(this.bonForm.value.pharmacies)
    console.log(this.pharmacies)
    if (this.bonForm.value.prescripteur!='' && this.bonForm.value.pharmacies!=null && this.file) {
      const formData: FormData = new FormData();
      formData.append('Pharmacie', this.pharmacies);
      formData.append('Prescripteur', this.bonForm.value.prescripteur);
      formData.append('Ordonnance', this.file, this.file.name);
      this.bonsService.genebon(formData).subscribe(response=> {
            Swal.fire('MSAE', 'Votre demande de bon de commande a été pris en compte', 'success'); 
            this.router.navigateByUrl('/').then(() => { // Redirige vers la page d'accueil après la connexion
              window.location.reload(); // Force la réinitialisation de l'état de la navigation
            });
        }, error => {
            Swal.fire('MSAE', 'Une erreur s\'est produite lors de l\'envoie de votre demande veuillez reessayer ultérieurement', 'error');
        }
      )
    }
    else{
        Swal.fire('MSAE', 'L\'ordonnance est obligatoire', 'error');
    }
  }
    */
}
