import { Injectable } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { SpinnerserviceService } from '../_services/spinnerservice.service';
import { finalize } from 'rxjs/internal/operators/finalize';
import { catchError, tap } from 'rxjs/operators';


@Injectable()
export class AdmininterceptorInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService,private spinnerService: SpinnerserviceService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>>  {
    // Ajoute l'en-tête d'autorisation si l'utilisateur est connecté
    this.spinnerService.set(true);
    const authToken = this.authService.loadToken();
    
    if(req.url.startsWith('https://portail.msae.sn:8081/api/sendmail/test')) {
      const clonedRequest = req.clone({ 
      });

      return next.handle(clonedRequest).pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            // Process the response if needed
            console.log('Response from target URL intercepted:', event);
          }
        }),
        catchError(error => {
          console.error('Error from target URL:', error);
          return throwError(error);
        })
      );
    }
    else if(req.url.startsWith('https://portail.msae.sn:7443/com.msae-index/api/authentification/v1/login')) {
      const clonedRequest = req.clone({
      // headers: req.headers.set('Access-Control-Allow-Origin', '*') 
      });
      return next.handle(req).pipe(
        
        finalize(() => {
          this.spinnerService.set(false);
        }),
  
      );;
    }
    else {
      if (authToken) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${authToken}`
          }
        });
      }

      //console.log('appelle')
     
  
      return next.handle(req).pipe(
        
        finalize(() => {
          this.spinnerService.set(false);
        }),
  
      );;
    }
  }
}
