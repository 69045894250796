import { Component } from '@angular/core';
import { SpinnerserviceService } from 'src/app/_services/spinnerservice.service';
@Component({
  selector: 'app-spinners',
  templateUrl: './spinners.component.html',
  styleUrls: ['./spinners.component.css']
})
export class SpinnersComponent {
  isLoading$ = this.spinnerService.loading$;
  constructor(public spinnerService: SpinnerserviceService) {}
}
