import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-remboursements',
  templateUrl: './remboursements.component.html',
  styleUrls: ['./remboursements.component.scss']
})
export class RemboursementsComponent {

  constructor(private router: Router) {

  }


  navigateMedicament() {
    this.router.navigateByUrl("/admin/medicaments")
  }

  navigateHospitalisation() {
    this.router.navigateByUrl("/admin/hospitalisations")
  }

  navigateOrdonnances() {
    this.router.navigateByUrl("/admin/ordonnances")
  }

  navigateAutres_soins() {
    this.router.navigateByUrl("/admin/autres_soins")
  }
}
