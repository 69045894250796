import { Component, OnInit, ViewChild } from "@angular/core";
import { DataTableDirective } from 'angular-datatables';
// import { DataTables } from 'datatables.net';
import { Subject } from 'rxjs';
import { UserService } from '../../../_services/user.service';
import { RecapService } from "src/app/_services/recap.service";

@Component({
  selector: "app-tables",
  templateUrl: "./tables.component.html",
  styleUrls: ["./tables.component.scss"]
})
export class TablesComponent implements OnInit {
  
  // @ViewChild(DataTableDirective, {static: false})
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  datatableElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dashboard: any;
  errorMessage: any;
  constructor(private recapService: RecapService) { }
  ngOnInit(): void {
    this.getbons();
 
 
    this.dtOptions  = {
     
      language: {url: '//cdn.datatables.net/plug-ins/1.10.22/i18n/French.json'},
      search: true,
      paging: false,
      ordering: true,
      // lengthMenu: [[10, 25, 50, -1], ["", "25", "50", "Tout"]],
      destroy: true,

      pageLength: 20,
    
      processing: true,
      // lengthMenu: [20, 50, 100],
     
    };
    // this.employees = this.loadEmployees();
  }
  

  async getbons() {
    await this.recapService.getDashboard().subscribe({
      next: data => {
        this.dashboard = data;
      
      },
      error: err => {
        this.errorMessage = err.error.message;
      }}
    );
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
}
