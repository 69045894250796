import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class SettingService {
  private baseUrl = environment.baseurl+'api/v1/infosAdherent';
  constructor(private http: HttpClient) { 
    this.getDashboard()
  }

  getDashboard(): Observable<any>{
      return this.http.get(this.baseUrl);
  }
}
