import { Component, OnInit } from "@angular/core";
import { AuthService } from '../../../_services/auth.service';
import { StorageService } from '../../../_services/storage.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
// import { Token } from "@angular/compiler";
import { SpinnerserviceService } from "src/app/_services/spinnerservice.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AuthGuard } from "src/app/_guards/authguard.guard";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
showContactForm: any;
loginFormGroup: FormGroup;
  isContactFormVisible = false; // Remplace showContactForm

openContactForm1() {
throw new Error('Method not implemented.');
}
  LoginForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });
  // isLoggedIn = false;
  // isLoginFailed = true;
  pass: any;
  errorMessage = '';
  roles: string[] = [];
  isLoading$ = this.spinnerService.loading$;


  constructor(private authService: AuthService, private storageService: StorageService, private router: Router,public spinnerService: SpinnerserviceService, private auth: AuthGuard) {
    
  }

  

  ngOnInit(): void {
    if (this.storageService.isLoggedIn()) {
      // this.isLoggedIn = true;
      this.roles = this.storageService.getUser().roles;
    }
  }
  save(): void {
    // console.log(this.LoginForm.value);
    this.authService.setPassword(this.LoginForm.value.password);
    this.authService.login(this.LoginForm.value).subscribe({
      next: data => {
        this.authService.setToken(data.token)
        this.auth.setConnection();
        this.router.navigateByUrl('/admin/dashboard').then(() => { // Redirige vers la page d'accueil après la connexion
          //window.location.reload(); // Force la réinitialisation de l'état de la navigation
        });
        Swal.fire('MSAE', 'Vous êtes connecté avec succès !', 'success')
       
        
      },
      error: err => {
        this.errorMessage = err.message;
        // this.isLoginFailed = true;
        // alert(this.errorMessage);
        Swal.fire('MSAE', 'Veuillez vérifier votre login  ou mot de passe', 'error')
      }}
    );
    
  }
  reloadPage(): void {
    window.location.reload();
  }
}
