import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { BonsService } from 'src/app/_services/bons.service';
import Swal from 'sweetalert2';

interface FilePreview {
  name: string;
  type: string;
  size: number;
  previewUrl: string;
}

@Component({
  selector: 'app-hospitalisations',
  templateUrl: './hospitalisations.component.html',
  styleUrls: ['./hospitalisations.component.scss']
})
export class HospitalisationsComponent {
  [x: string]: any;
  overlay = false;
    errorMessage :any;
    data: any ;
    file:any;
    fileMedicament: any
    fileVignette: any
    fileTicket: any
    fileFacture: any
    tel: string = ""

    public files: FilePreview[] = [];

    public filesMedicament: FilePreview[] = [];

    public filesVignette: FilePreview[] = [];

    public filesTicket: FilePreview[] = [];


    public isDragging = false;
    public isDraggingFacture = false;
    public isDraggingVignette = false;
    public isDraggingTicket = false;

    constructor(private bonService: BonsService, private authservice: AuthService) {

    }
  
    onFileSelected(files: FileList) {
      this.files = []
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.file = file
        this.previewFile(file);
      }
    }

    onFileSelectedFacture(filesMedicament: FileList) {
      this.filesMedicament = []
      for (let i = 0; i < filesMedicament.length; i++) {
        const file = filesMedicament[i];
        this.fileMedicament = file;
        this.previewFileFacture(file);
      }
    }

    onFileSelectedVignette(filesVignette: FileList) {
      this.filesVignette = []
      for (let i = 0; i < filesVignette.length; i++) {
        const file = filesVignette[i];
        this.fileVignette = file
        this.previewFileVignette(file);
      }
    }

    onFileSelectedTicket(filesTicket: FileList) {
      this.filesTicket = []
      for (let i = 0; i < filesTicket.length; i++) {
        const file = filesTicket[i];
        this.fileTicket = file
        this.previewFileTicket(file);
      }
    }
  
    onDragOver(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();
      this.isDragging = true;
    }

    onDragOverFacture(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();
      this.isDraggingFacture = true;
    }

    onDragOverVignette(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();
      this.isDraggingVignette = true;
    }
  
    onDragOverTicket(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();
      this.isDraggingTicket = true;
    }
    onDragLeave(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();
      this.isDragging = false;
    }

    onDragLeaveFacture(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();
      this.isDraggingFacture = false;
    }

    onDragLeaveVignette(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();
      this.isDraggingVignette = false;
    }

    onDragLeaveTicket(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();
      this.isDraggingVignette = false;
    }
  
    onDrop(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();
      this.isDragging = false;
      this.files = []
      const files = event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.file = file
        this.previewFile(file);
      }
    }

    onDropFacture(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();
      this.isDragging = false;
      this.filesMedicament = []
      const files = event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.fileMedicament = file
        this.previewFileFacture(file);
      }
    }

    onDropVignette(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();
      this.isDragging = false;
      this.filesVignette = []
      const files = event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.fileVignette = file
        this.previewFileVignette(file);
      }
    }

    
    onDropTicket(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();
      this.isDragging = false;
      this.filesTicket = []
      const files = event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.fileTicket = file
        this.previewFileTicket(file);
      }
    }
  
    previewFile(file: File) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const filePreview: FilePreview = {
          name: file.name,
          type: file.type,
          size: file.size,
          previewUrl: reader.result as string
        };
        this.files.push(filePreview);
      };
    }

    previewFileFacture(file: File) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const filePreview: FilePreview = {
          name: file.name,
          type: file.type,
          size: file.size,
          previewUrl: reader.result as string
        };
        this.filesMedicament.push(filePreview);
      };
    }

    previewFileVignette(file: File) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const filePreview: FilePreview = {
          name: file.name,
          type: file.type,
          size: file.size,
          previewUrl: reader.result as string
        };
        this.filesVignette.push(filePreview);
      };
    }

    previewFileTicket(file: File) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const filePreview: FilePreview = {
          name: file.name,
          type: file.type,
          size: file.size,
          previewUrl: reader.result as string
        };
        this.filesTicket.push(filePreview);
      };
    }

  
    //ajout du 16/08/2024
    save() {
      if(this.authservice.getEligible() === 'ST00002') {
        if(this.file && this.fileMedicament && this.tel != ""){

          const form: FormData = new FormData();
          form.append('billetHosp', this.fileMedicament, 'billetHosp');
          form.append('bulletin', this.file, 'bulletin');
          form.append('billetCon', null, 'billetCon'); //this.fileVignette
          form.append('telephone', this.tel);
        
          this.bonService.rembhosp(form).subscribe(response=> {
            Swal.fire('MSAE', 'Votre demande de remboursement  d\'hospitalisation a été prise en compte. Une date de rendez-vous vous sera communiquée trés prochainement.', 'success');
            
            this.router.navigateByUrl('/admin/dashboard').then(() => { // Redirige vers la page d'accueil après la connexion
            });
            }, error => {
                Swal.fire('MSAE', 'Une erreur s\'est produite lors de l\'envoi de votre demande. Veuillez réessayer ultérieurement.', 'error');
            }
          )

        }
        else {
          Swal.fire('champs', 'Veuillez renseigner les champs.', 'warning')
        }
      }
      else {
        Swal.fire('Eligibilité', "Vous n'êtes pas éligible.", 'warning')
      }

    }
  

  removeFile(index: number) {
    this.files.splice(index, 1);
    this.files = []

  }

  //ajout
  


}

