import { Component, OnInit, ViewChild, ElementRef, AfterViewInit} from "@angular/core";
import { Subject } from "rxjs";
import { DataTableDirective } from 'angular-datatables';
// import { ModalsComponent } from 'src/app/components/modals/modals.component';
import { RecapService } from "src/app/_services/recap.service";
// import { createPopper } from "@popperjs/core";
import '@angular/common/locales/fr';
import { AuthService } from "src/app/_services/auth.service";
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {
  months: string[] = ['jan', 'fév', 'mar', 'avr', 'mai', 'jun', 'jul', 'aoû', 'sep', 'oct', 'nov', 'déc'];
  
  //ajout du 12/08/2024
  currentMonth: string = '';
  // previousMonth: string = '';
  //today:any;
  options: any;

  today: Date = new Date();
  // paiements: any[];
  thisday:any;
  dashboard: any;
  showModal = false;
  errorMessage: any;
  validMonthsarray : string[]=[];
  dropdownPopoverShow = false;
  document2: any;
  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef: ElementRef;
  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef: ElementRef;
   // @ViewChild(DataTableDirective, {static: false})
   @ViewChild(DataTableDirective) dtElement: DataTableDirective;
   datatableElement: DataTableDirective;
 
   dtOptions: DataTables.Settings = {};
   dtTrigger: Subject<any> = new Subject();
 
 //fin ajout

  constructor(private recapService: RecapService, private authService: AuthService)  {
    const date = new Date();
    const monthIndex = date.getMonth();
    this.today = date;
    //ajout du 12/08/2024
    this.currentMonth = this.months[date.getMonth()];
    const month = date.toLocaleString('default', { month: 'short' });
    this.currentMonth = this.months[monthIndex];

    //fin ajout du 12/08/2024

  }
  ngOnInit(): void {


    this.dtOptions  = {
      language: {url: '//cdn.datatables.net/plug-ins/1.10.22/i18n/French.json'},
      // scrollY: '200px',
      // scrollCollapse: true,
      search: true,
      paging: false,
      ordering: true,
      // lengthMenu: [[10, 25, 50, -1], ["", "25", "50", "Tout"]],
      destroy: true,
      pageLength: 20,
      processing: true,
      // lengthMenu: [20, 50, 100],
    };
  
    this.getdashboard()
    
  
  }
  
  async getdashboard() {
    await this.recapService.getDashboard().subscribe({
      next: data => {
        this.dashboard = data;
        this.authService.setEligible(data.adherent.eligibilite)
        this.isMonthValid(this.dashboard.cotisations.length);
        
      },
      error: err => {
        this.errorMessage = err.error.message;
      }}
    );
  }

  isPreviousMonth(month: any) {
    //const currentMonth = this.today.toLocaleString('fr-FR', this.options);
    //ajout du 12/08/2024
    const currentMonth = this.today.toLocaleString('fr-FR', { month: 'long' });
    return month === currentMonth;
    //fin ajout du 12/08/2024


    if (month == currentMonth) {
      return true;
    }
    else{
      return false
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  // isMonthValid(index: number): boolean {
  //   const today = new Date();
  //   const currentMonth = today.getMonth();
  //   const monthToCheck = (currentMonth + index) % 12;
  //   const validMonths = ['janv.', 'févr.', 'mars', 'avr.'];
  //   const monthName = this.months[monthToCheck];
  //   return validMonths.includes(monthName);
  // }

  ////////modif du 12/08/2024
  //async isMonthValid(month: any){
    //const validMonths = ['jan', 'fév', 'mar', 'avr', 'mai', 'jun', 'jul', 'aoû', 'sep', 'oct', 'nov', 'déc'];
    //for (var i = 0; i <month; i++) {
        //this.validMonthsarray.push(validMonths[i])
        // console.log(this.validMonthsarray[i]);
    //}
    
    //return this.validMonthsarray
  //}

  //ajout du 12/08/2024
  async isMonthValid(month: any){
    const validMonths = this.months;
    for (let i = 0; i < month; i++) {
      this.validMonthsarray.push(validMonths[i]);
    }
    return this.validMonthsarray;
  }
  //fin ajout du 12/08/2024
 
  toggleDropdown(event) {
    event.preventDefault();
    if (this.dropdownPopoverShow) {
      this.dropdownPopoverShow = false;
    } else {
      this.dropdownPopoverShow = true;
    }
  }
  modalOpen = false;

  openModal() {
    this.modalOpen = true;
  }
}
