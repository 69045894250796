import { Component, OnInit, ViewChild } from "@angular/core";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { UserService } from "src/app/_services/user.service";
@Component({
  selector: "app-maps",
  templateUrl: "./maps.component.html",
})
export class MapsComponent implements OnInit {
  // @ViewChild(DataTableDirective, {static: false})
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  datatableElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  employees = [ 
                {
                  name: 'Mbotty pom ',
                  position: 'pharmacie',type:'pharmacy', 
                  office: 'medina', age: '30', 
                  startDate: '01/02/2019', 
                  salary: '90,000' 
                }
              ];
  allUsers: any = [];
  constructor(private service: UserService) { }
  ngOnInit(): void {
    this.users();
  
    // this.dtOptions = {
    //   pagingType: 'full_numbers',
    //   pageLength: 10,
    //   processing: true,  
    //   serverSide: true,
    //   searching:true,
    //   search: {
    //     caseInsensitive: true
    //   }
    // };
    this.dtOptions = {
      pagingType: 'full_numbers',
      language: {url: '//cdn.datatables.net/plug-ins/1.10.22/i18n/French.json'},
      search: true,
      paging: true,
      ordering: true,
      destroy: true,
      pageLength: 20,
      processing: true,
      lengthMenu: [20, 50, 100],
      columns: [
        { title: 'Type de partenaires', data: 'type' },
        { title: 'Localisation', data: 'location' },
        // { title: 'Gender', data: 'gender' }
      ]
    };
    // this.employees = this.loadEmployees();
  }
  

  users(): void {
    this.service
        .users()
        .subscribe((response: any) => {
          this.allUsers = response.data;
        });
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

}
