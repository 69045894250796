import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HttpClientModule } from '@angular/common/http';





// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";


// admin views
import { DashboardComponent } from "./views/admin/dashboard/dashboard.component";
import { MapsComponent } from "./views/admin/maps/maps.component";
import { BonsComponent } from "./views/admin/bons/bons.component";
import { AutresComponent } from "./views/admin/remboursements/autres/autres.component";
import { HospitalisationsComponent } from "./views/admin/remboursements/hospitalisations/hospitalisations.component";
import { RemboursementsComponent } from "./views/admin/remboursements/remboursements/remboursements.component";
import { OrdonnancesComponent } from "./views/admin/remboursements/ordonnances/ordonnances.component";
import { MedicamentsComponent } from "./views/admin/remboursements/medicaments/medicaments.component";
import { SettingsComponent } from "./views/admin/settings/settings.component";
import { TablesComponent } from "./views/admin/tables/tables.component";
import { ContactComponent } from "./views/admin/contact/contact.component";


// auth views
import { LoginComponent } from "./views/auth/login/login.component";
// import { RegisterComponent } from "./views/auth/register/register.component";

// // no layouts views
// import { IndexComponent } from "./views/index/index.component";
// import { LandingComponent } from "./views/landing/landing.component";
 import { ProfileComponent } from "./views/profile/profile.component";
import { AuthGuard } from "./_guards/authguard.guard";
import { ChangePasswordComponent } from "./views/admin/change-password/change-password.component";

const routes: Routes = [
  // admin views
  {
    path: "admin",
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "dashboard", component: DashboardComponent, canActivateChild: [AuthGuard] },
      { path: "settings", component: SettingsComponent, canActivateChild: [AuthGuard] },
      { path: "change-password", component: ChangePasswordComponent, canActivateChild: [AuthGuard] },
      { path: "password", component: ChangePasswordComponent, canActivateChild: [AuthGuard] },
      { path: "tables", component: TablesComponent , canActivateChild: [AuthGuard]},
      { path: "bons", component: BonsComponent, canActivateChild: [AuthGuard] },
      { path: "autres_soins", component: AutresComponent, canActivateChild: [AuthGuard] },
      { path: "hospitalisations", component: HospitalisationsComponent, canActivateChild: [AuthGuard] },
      { path: "remboursements", component: RemboursementsComponent, canActivateChild: [AuthGuard] },
      { path: "ordonnances", component: OrdonnancesComponent, canActivateChild: [AuthGuard] },
      { path: "medicaments", component: MedicamentsComponent, canActivateChild: [AuthGuard] },
      { path: "maps", component: MapsComponent, canActivateChild: [AuthGuard] },
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
    ]
  },
  // auth views
  {
    path: "login", 
    component: LoginComponent
  },
  { 
    path: "contact", 
    component: ContactComponent
  },
  {
    path: "",
    component: AuthComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "admin", component: AdminComponent, canActivateChild: [AuthGuard]},
      { path: "", redirectTo: "admin", pathMatch: "full" },
    ],
  },
 
  // no layout views
  // { path: "profile", component: ProfileComponent },
  // { path: "landing", component: LandingComponent },
  // { path: "", component: IndexComponent },
  { path: "**", redirectTo: "", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  
  exports: [RouterModule],
})
export class AppRoutingModule {}
