import { Component, OnInit , Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: "app-admin-navbar",
  templateUrl: "./admin-navbar.component.html",
  styleUrls: ["./admin-navbar.component.scss"]
})
export class AdminNavbarComponent implements OnInit {
  showModal = false;
  constructor() {
    this.showModal = false;
    //console.log(this.showModal)
  }

  ngOnInit(): void {
    this.showModal = false;
  }
  toggleModal(){
    this.showModal = !this.showModal;
  }
 
  
}
