import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { BonsService } from 'src/app/_services/bons.service';
import Swal from 'sweetalert2';
interface FilePreview {
  name: string;
  type: string;
  size: number;
  previewUrl: string;
}

@Component({
  selector: 'app-autres',
  templateUrl: './autres.component.html',
  styleUrls: ['./autres.component.scss']
})
export class AutresComponent {
  [x: string]: any;
  overlay = false;
    errorMessage :any;
    data: any ;
    file:any;
    fileMedicament: any
    tel: string =""
  public files: FilePreview[] = [];
  public filesVignette: FilePreview[] = [];


  
  public isDragging = false;
  public isDraggingVignette = false;
  
  constructor (private bonsService : BonsService, private router : Router, private authService: AuthService){

  }

  onFileSelected(files: FileList) {
    this.files = []
    
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      this.file = file
      this.previewFile(file);
    }
  }

   
  onFileSelectedVignette(filesVignette: FileList) {
    this.filesVignette = []
    for (let i = 0; i < filesVignette.length; i++) {
      const file = filesVignette[i];
      this.fileMedicament = file
      this.previewFileVignette(file);
    }
  }
  
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = true;
  }

  onDragOverVignette(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDraggingVignette = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = false;
  }

  onDragLeaveVignette(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDraggingVignette = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = false;
    this.files = []
    const files = event.dataTransfer.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      this.file = file;
      this.previewFile(file);
    }
  }

  onDropVignette(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = false;
    this.filesVignette = []
    const files = event.dataTransfer.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      this.fileMedicament = file
      this.previewFileVignette(file);
    }
  }

  previewFile(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const filePreview: FilePreview = {
        name: file.name,
        type: file.type,
        size: file.size,
        previewUrl: reader.result as string
      };
      this.files.push(filePreview);
    };
  }

  previewFileVignette(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const filePreview: FilePreview = {
        name: file.name,
        type: file.type,
        size: file.size,
        previewUrl: reader.result as string
      };
      this.filesVignette.push(filePreview);
    };
  }


  removeFile(index: number) {
    this.files.splice(index, 1);
  }

  onUpload() {
    if(this.authService.getEligible() === 'ST00002') {

      if (this.file && this.fileMedicament && this.tel != "") {

        const formData: FormData = new FormData();
       
        formData.append('billet', this.file, this.file.name);
        formData.append('facture', this.fileMedicament, 'facture');
        formData.append('telephone', this.tel)

        this.bonsService.rembaut(formData).subscribe(response=> {
            Swal.fire('MSAE', 'Votre demande de remboursement a été prise en compte. Une date de rendez-vous vous sera communiquée trés prochainement', 'success');
            
            this.router.navigateByUrl('/admin/dashboard').then(() => { // Redirige vers la page d'accueil après la connexion
            });
        }, error => {
            Swal.fire('MSAE', 'Une erreur s\'est produite lors de l\'envoi de votre demande. Veuillez réessayer ultérieurement', 'error');
        }
        )
        
      }
      else{
          Swal.fire('MSAE', 'Veuillez remplir les champs', 'warning');
      }
    }
    else {
      Swal.fire('MSAE', "vous n'êtes pas éligible", 'warning');
    }
  }
}
