import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AuthGuard } from 'src/app/_guards/authguard.guard';
import { AuthService } from 'src/app/_services/auth.service';
import { SettingService } from 'src/app/_services/setting.service';

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls:["sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
  collapseShow = "hidden";
 
  
  ngOnInit(): void {
    this.getsetting()
  }
  // toggleCollapseShow(classes) {
  //   this.collapseShow = classes;
  // }
  pages = ['one', 'two', 'three', 'four'];
  user: any;
  errorMessage: any;


  constructor(private elementRef: ElementRef, private renderer: Renderer2,private settingService: SettingService,private authService: AuthService, private router: Router, private auth: AuthGuard) {}

  toggleMenu(): void {
    const wrapper = document.getElementsByClassName('wrapper')[0] as HTMLElement;
    wrapper.classList.toggle('menu-open');
  }

  goToPage(page: number): void {
    const wrapper = document.getElementsByClassName('wrapper')[0] as HTMLElement;
    const sections = document.getElementsByTagName('section');
    for (let i = 0; i < sections.length; i++) {
      sections[i].classList.remove('before', 'after');
      if (i > page) {
        sections[i].classList.add('after');
      }
    }
    wrapper.classList.remove('menu-open', 'page-one', 'page-two');
    wrapper.classList.add(`page-${this.pages[page]}`);
  }
  toggleMenu2(){
    const wrapper = document.getElementsByClassName('btn-pluss-wrapper')[0] as HTMLElement;
    wrapper.classList.toggle('btn-pluss-wrapper2');
  }
  
  async getsetting() {
    await this.settingService.getDashboard().subscribe({
      next: data => {
        this.user = data;
        // console.log(this.user.membreFamille);
      },
      error: err => {
        this.errorMessage = err.error.message;
      }}
    );
  }

  logout() {
    this.authService.logout();
    this.auth.setConnection();
    this.router.navigateByUrl('/login')
  }
}
