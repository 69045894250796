import { Component } from '@angular/core';
import { UserService } from 'src/app/_services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/_services/auth.service';
import { Router } from '@angular/router';
import { SpinnerserviceService } from 'src/app/_services/spinnerservice.service';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
contactForm: any;
formGroup: FormGroup;
changePassword: any;

showContactForm: any;
loginFormGroup: FormGroup;
  isContactFormVisible = false; // Remplace showContactForm

openContactForm1() {
throw new Error('Method not implemented.');
}

  
  // isLoggedIn = false;
  // isLoginFailed = true;
  pass: any;
  errorMessage = '';
  roles: string[] = [];
  isLoading$ = this.spinnerService.loading$;
  constructor(private authService: AuthService,private formbuilder: FormBuilder, private router: Router,public spinnerService: SpinnerserviceService,) {
    
   }

  

  ngOnInit(): void {
    this.contactForm = this.formbuilder.group({
      nom: [null, [Validators.required]],
      email: [null, [Validators.required]],
      matricule: [null, [Validators.required]],
      telephone: [null, [Validators.required]],
      objet: [null, [Validators.required]],
      message: [null, [Validators.required]],
    })    
  }

  contact(){
    
    //console.log(this.contactForm.value);

    const data = {
      ... this.contactForm.value
    }
     
    this.authService.contact(data).subscribe( value => {
      //console.log(value)
    })
    //ajout 
    if (this.contactForm.valid) {
      this.authService.contact(this.contactForm.value).subscribe({
        next: (response) => {
          Swal.fire('Succès', 'Votre message a été envoyé avec succès!', 'success');
          this.router.navigate(['login'])

        },
        error: (err) => {
          Swal.fire('Information', 'Veuillez patienter quelques minutes', 'info');

        }
      });
    } else {
      Swal.fire('Alerte', 'Veuillez remplir tous les champs requis.', 'warning');
    }
    //fin ajout
  }


  save(): void {
    
    // console.log(this.LoginForm.value);
    
    
  }
  reloadPage(): void {
    window.location.reload();
  }
}
